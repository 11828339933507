// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "3912db96b98551cde4da1f3043b3044e0c9c46d7",
  version: "1.102.4",
};
