import { Option } from "fp-ts/es6/Option";
import * as D from "io-ts/Decoder";

import { PaymentMethod } from "src/app/shared/modules/stripe/stripe-definitions/payment-method";
import { pipe } from "fp-ts/function";
import Stripe from "stripe";
/**
 * @todo: pipe and type are now deprecated, update before upgrading
 */
/**
 * These models were initially pulled from qqsystem-types. We repeat
 * their implementation here because the myqq service is growing
 * independent of the existing microservices.
 *
 * The models have now been implemented as io-ts decoders.
 */

export const numberOrString = D.union(D.string, D.number);

/**
 * Though it rather defeats the point, we need an 'unkown' decoder for one of the Stripe models
 */

export const unknownDecoder: D.Decoder<unknown, unknown> = {
  decode: (u) => D.success(u),
};

export const stripeAPISubscriptionDecoder: D.Decoder<
  unknown,
  Stripe.Subscription
> = {
  decode: (sub: Stripe.Subscription) => D.success(sub),
};

/**
 * io-ts does not natively support enums, so decoderFromEnum can be used
 * to generate a decoder for an enum
 */

/**
 * Enum definitions
 */
export enum StripeStatusEnum {
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  TRIALING = "trialing",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
  NONE = "no_active_subscriptions",
}
export interface MembershipPurchase {
  wash: SubscriptionPlan;
  vehicles: Vehicle[];
}

export enum SubscriptionItemTypeEnum {
  base = "base",
  flock = "flock",
}

export enum SalesItemTypesEnum {
  Membership = "MEMBERSHIP",
  PrepaidCard = "PREPAID",
  Subscription = "SUBSCRIPTION",
  WashPackage = "WASHPACKAGE",
}

export enum OrderTypesEnum {
  STORE = "STORE",
  REFUND = "REFUND",
  RENEWAL = "RENEWAL",
  ADDMEMBERSHIP = "ADDMEMBERSHIP",
  NEWMEMBERSHIP = "NEWMEMBERSHIP",
  UPGRADEMEMBERSHIP = "UPGRADEMEMBERSHIP",
  DOWNGRADEMEMBERSHIP = "DOWNGRADEMEMBERSHIP",
  UPGRADESUBSCRIPTION = "UPGRADESUBSCRIPTION",
  DOWNGRADESUBSCRIPTION = "DOWNGRADESUBSCRIPTION",
  REMOVEMEMBERSHIP = "REMOVEMEMBERSHIP",
  REMOVESUBVEHICLE = "REMOVESUBVEHICLE",
  CANCELSUB = "CANCELSUB",
  CARDFLIGHTCONVERT = "CARDFLIGHTCONVERT",
}

/** This enum is not definitive;
 * cancelation reasons are pulled from the backend at /cancelreasons.
 * Current reasons are duplicated here to simplify retention offer eligibility check.
 */

export enum CancelReasonsEnum {
  MOVED = "MOVED",
  SOLDCAR = "SOLDCAR",
  NONUSE = "NONUSE",
  DAMAGED = "DAMAGED",
  INCOMPATIBLE = "INCOMPATIBLE",
  WEATHER = "WEATHER",
  UNSATISFIED = "UNSATISFIED",
  FINANCIAL = "FINANCIAL",
  SIGNUPERROR = "SIGNUPERROR",
  OTHER = "OTHER",
  FAILEDINVOICE = "FAILEDINVOICE",
}
/**
 * Function to create enum decoders
 *
 * @param theEnum the enum to create the decoder from
 */
export const decoderFromEnum = <
  T extends string,
  TEnumValue extends string | number
>(
  theEnum: { [key in T]: TEnumValue }
): D.Decoder<unknown, TEnumValue> => {
  const isEnumValue = (input: unknown): input is TEnumValue =>
    Object.values(theEnum).includes(input);

  const enumDecoder: D.Decoder<unknown, TEnumValue> = {
    decode: (input) =>
      isEnumValue(input)
        ? D.success(input)
        : D.failure(input, `one of ${Object.values(theEnum).join(", ")}`),
  };
  return enumDecoder;
};

export const StripeStatus = decoderFromEnum(StripeStatusEnum);
export type StripeStatus = D.TypeOf<typeof StripeStatus>;

export const SalesItemTypes = decoderFromEnum(SalesItemTypesEnum);
export type SalesItemTypes = D.TypeOf<typeof SalesItemTypes>;

export const SubscriptionItemType = decoderFromEnum(SubscriptionItemTypeEnum);
export type SubscriptionItemType = D.TypeOf<typeof SubscriptionItemType>;

export const OrderType = decoderFromEnum(OrderTypesEnum);
export type OrderType = D.TypeOf<typeof OrderType>;
/**
 * Decoders and types
 */
export const QsysProfile = pipe(
  D.struct({
    userName: D.string,
    name: D.string,
  }),
  D.intersect(
    D.partial({
      info: D.partial({
        // TODO: confirm these can be partials
        address: D.nullable(D.string),
        gender: D.nullable(D.string),
        height: D.nullable(D.number),
        qsysAccount: D.nullable(D.string),
      }),
    })
  )
);
export type QsysProfile = D.TypeOf<typeof QsysProfile>;

export const Vehicle = pipe(
  D.struct({
    vehicleId: D.string,
    verificationCode: D.string,
  }),
  D.intersect(
    D.partial({
      isVerified: D.nullable(D.boolean),
      personId: D.nullable(D.string),
      state: D.nullable(D.string),
      license: D.nullable(D.string),
      vin: D.nullable(D.string),
      make: D.nullable(D.string),
      model: D.nullable(D.string),
      color: D.nullable(D.string),
      year: D.nullable(D.string),
      isActive: D.nullable(D.boolean),
      hasMembership: D.nullable(D.boolean),
      expiring: D.nullable(D.boolean),
      softCanceled: D.nullable(D.boolean),
      cancelAt: D.nullable(D.number),
      subscriptionStatus: D.nullable(D.string),
      washLevelId: D.nullable(D.string),
      myQQShortDisplayName: D.nullable(D.string),
      isTemporaryIdentifier: D.nullable(D.boolean),
      createdOn: D.nullable(D.string),
      createdIn: D.nullable(D.string),
      createdBy: D.nullable(D.string),
      createdAt: D.nullable(D.string),
      updatedBy: D.nullable(D.string),
      updatedOn: D.nullable(D.string),
      updatedIn: D.nullable(D.string),
      updatedAt: D.nullable(D.string),
      deletedBy: D.nullable(D.string),
      deletedOn: D.nullable(D.string),
      deletedAt: D.nullable(D.string),
      washOptions: D.partial({
        grillBrushRetract: D.nullable(D.boolean),
        topBrushRetract: D.nullable(D.boolean),
        flexWrapRetract: D.nullable(D.boolean),
        tireShineRetract: D.nullable(D.boolean),
      }),
    })
  )
);
export type Vehicle = D.TypeOf<typeof Vehicle>;

export const VehicleArray = D.array(Vehicle);
export type VehicleArray = D.TypeOf<typeof VehicleArray>;

export const OrderAddress = pipe(
  D.struct({
    orderAddressId: D.string,
    orderId: D.string,
    description: D.string,
    contactName: D.string,
  }),
  D.intersect(
    D.partial({
      street1: D.nullable(D.string),
      street2: D.nullable(D.string),
      city: D.nullable(D.string),
      state: D.nullable(D.string),
      zipCode: D.nullable(D.string),
    })
  )
);
export type OrderAddress = D.TypeOf<typeof OrderAddress>;

export const Account = pipe(
  D.struct({ personId: D.string }),
  D.intersect(
    D.partial({
      employeeNumber: D.nullable(D.string),
      stripeCustomer: D.nullable(D.string),
      personType: D.nullable(D.string), // Not used by myQQ or v3, keep for now for v2 compatibility.
      firstName: D.nullable(D.string),
      lastName: D.nullable(D.string),
      birthDate: D.nullable(D.string),
      gender: D.nullable(D.string), // Backend gender enum needs to be fixed, for now just accept any string. TODO: improve backend, lock this down.
      email: D.nullable(D.string),
      primaryPh: D.nullable(D.string),
      secondaryPh: D.nullable(D.string),
      addresses: D.nullable(D.array(OrderAddress)),
      effectiveOn: D.nullable(D.string),
      expiresOn: D.nullable(D.string),
      homeStoreId: D.nullable(D.string),
      vehicles: D.nullable(D.array(Vehicle)),
      addressId: D.nullable(D.string),
      street1: D.nullable(D.string),
      street2: D.nullable(D.string),
      city: D.nullable(D.string),
      state: D.nullable(D.string),
      zipCode: D.nullable(D.string),
      countryId: D.nullable(D.string),
      createdBy: D.nullable(D.string),
      createdIn: D.nullable(D.string),
      createdAt: D.nullable(D.string),
      effectiveAt: D.nullable(D.string),
      expiresAt: D.nullable(D.string),
      memberSince: D.nullable(D.string),
      updatedAt: D.nullable(D.string),
      updatedBy: D.nullable(D.string),
      updatedIn: D.nullable(D.string),
      xrefId: D.nullable(D.string),
      regionId: D.nullable(D.string), // Used for account creation api calls only
      regionNumber: D.nullable(D.string),
    })
  )
);
export type Account = D.TypeOf<typeof Account>;

export const CouponIssued = pipe(
  D.struct({
    couponIssuedId: D.string,
    couponId: D.string,
    personId: D.string,
    storeId: D.string,
    effectiveAt: D.string,
    expiresAt: D.string,
  }),
  D.intersect(
    D.partial({
      stripePlanId: D.nullable(D.string),
      orderId: D.nullable(D.string),
      orderItemId: D.nullable(D.string),
      vehicleId: D.nullable(D.string),
      employee: D.nullable(D.string),
      email: D.nullable(D.string),
      serialNo: D.nullable(D.string),
      overridePrice: D.nullable(D.string),
      priceInclTax: D.nullable(D.boolean),
      discountAmt: D.nullable(D.string),
      discountPct: D.nullable(D.string),
      refItemForPrice: D.nullable(D.string),
      maxRedemptions: D.nullable(D.number),
      remainingRedemptions: D.nullable(D.number),
      redemptionValue: D.nullable(D.string),
      limitRedemptions: D.nullable(D.boolean),
      reasonCode: D.nullable(D.string),
      reasonNotes: D.nullable(D.string),
      couponType: D.nullable(D.string),
      name: D.nullable(D.string),
      storeNumber: D.nullable(D.string),
      employeeId: D.nullable(D.string),
      employeeFirst: D.nullable(D.string),
      employeeLast: D.nullable(D.string),
      createdBy: D.nullable(D.string),
      createdIn: D.nullable(D.string),
      createdAt: D.nullable(D.string),
      updatedBy: D.nullable(D.string),
      updatedIn: D.nullable(D.string),
      updatedAt: D.nullable(D.string),
    })
  )
);
export type CouponIssued = D.TypeOf<typeof CouponIssued>;

export const StripeSubscriptionNew = pipe(
  D.struct({
    currentPeriodStart: D.string,
    currentPeriodEnd: D.string,
    stripeVehicles: D.nullable(D.array(Vehicle)),
    upcomingInvoice: unknownDecoder,
    invoiceAmount: D.nullable(D.string),
    invoiceId: D.nullable(D.string),
    status: D.nullable(D.union(StripeStatus, D.literal(""))),
    cancelAtPeriodEnd: D.nullable(D.boolean),
  })
);
export type StripeSubscriptionNew = D.TypeOf<typeof StripeSubscriptionNew>;

export const AccountInfoNew = D.struct({
  account: Account,
  vehicles: D.array(Vehicle),
  activeCoupons: D.array(CouponIssued),
  stripeSubscription: StripeSubscriptionNew,
});
export type AccountInfoNew = D.TypeOf<typeof AccountInfoNew>;

export const AccountInfoResponse = D.struct({
  profile: QsysProfile,
  account: D.nullable(AccountInfoNew),
});
export type AccountInfoResponse = D.TypeOf<typeof AccountInfoResponse>;

export const Store = pipe(
  D.struct({
    name: D.string,
    addressLine1: D.string,
    city: D.string,
    state: D.string,
    zipCode: D.string,
    phoneNumber: D.string,
  }),
  D.intersect(
    D.partial({
      storeId: D.string,
      regionId: D.string,
      storeNumber: D.string,
      defaultLaneNumber: D.number,
      addressLine2: D.nullable(D.string),
      regionNumber: D.nullable(D.string),
      grandOpeningAt: D.nullable(D.string),
      cashDrawerId: D.nullable(D.string),
      printerId: D.nullable(D.string),
      createdAt: D.nullable(D.string),
      updatedAt: D.nullable(D.string),
      connectAccountId: D.nullable(D.string),
      timeZone: D.nullable(D.string),
      lat: D.number,
      lng: D.number,
      open: D.nullable(D.string),
      close: D.nullable(D.string),
      manager: D.nullable(D.string),
    })
  )
);
export type Store = D.TypeOf<typeof Store>;

export const StoreArray = D.array(Store);
export type StoreArray = D.TypeOf<typeof StoreArray>;

export const Region = pipe(
  D.struct({
    regionId: D.string,
    regionNumber: D.string,
    name: D.string,
    state: D.string,
    timeZone: D.string,
  }),
  D.intersect(
    D.partial({
      createdBy: D.nullable(D.string),
      createdOn: D.nullable(D.string),
      createdAt: D.nullable(D.string),
      updatedBy: D.nullable(D.string),
      updatedOn: D.nullable(D.string),
      updatedAt: D.nullable(D.string),
      deletedBy: D.nullable(D.string),
      deletedOn: D.nullable(D.string),
      deletedAt: D.nullable(D.string),
    })
  )
);
export type Region = D.TypeOf<typeof Region>;

export const RegionArray = D.array(Region);
export type RegionArray = D.TypeOf<typeof RegionArray>;

export const OrderHold = pipe(
  D.struct({
    orderHoldId: D.string,
    orderId: D.string,
    holdCode: D.string,
    message: D.string,
  }),
  D.intersect(
    D.partial({
      orderItemId: D.nullable(D.string),
      voidOrder: D.nullable(D.boolean),
      overrideBy: D.nullable(D.string),
      holdOn: D.nullable(D.string),
      holdBy: D.nullable(D.string),
    })
  )
);
export type OrderHold = D.TypeOf<typeof OrderHold>;

export const OrderItem = pipe(
  D.struct({
    itemId: D.string,
    orderedQty: D.union(D.number, D.string),
  }),
  D.intersect(
    D.partial({
      orderItemId: D.nullable(D.string),
      orderId: D.nullable(D.string),
      vehicleId: D.nullable(D.string),
      name: D.nullable(D.string),
      sku: D.nullable(D.string),
      unitPrice: D.nullable(D.union(D.number, D.string)),
      totalTaxable: D.nullable(D.union(D.number, D.string)),
      taxRate: D.nullable(D.union(D.number, D.string)),
      priceInclTax: D.nullable(D.boolean),
      totalMarkDowns: D.nullable(D.union(D.number, D.string)),
      subTotal: D.nullable(D.union(D.number, D.string)),
      grossTotal: D.nullable(D.union(D.number, D.string)),
      taxTotal: D.nullable(D.union(D.number, D.string)),
      lineTotal: D.nullable(D.union(D.number, D.string)),
    })
  )
);
export type OrderItem = D.TypeOf<typeof OrderItem>;

const orderMarkDownRequired = {
  serialNo: D.string,
};

const ordermarkDownPartial = {
  orderMarkDownId: D.string,
  orderId: D.string,
  markDownId: D.string,
  markDownName: D.string,
  discountAmt: D.string,
  campaignId: D.string,
  orderItemId: D.string,
  couponId: D.string,
  couponIssuedId: D.string,
  subscriptionItemId: D.string,
  reasonNotes: D.string,
  discountPct: D.string,
  discountType: D.literal(
    "MEMBERSHIP",
    "EMPLOYEEMBR",
    "MANUAL",
    "BALANCE",
    "FLOCK",
    "REWASH",
    "SMARTDISCOUNT",
    "EMPLOYEE"
  ),
};

export const OrderMarkDown = pipe(
  D.struct(orderMarkDownRequired),
  D.intersect(D.partial(ordermarkDownPartial))
);
export type OrderMarkDown = D.TypeOf<typeof OrderMarkDown>;

export const OrderMarkUp = pipe(
  D.struct({
    orderMarkUpId: D.string,
    orderId: D.string,
    markUpType: D.string,
    markUpId: D.string,
    markUpName: D.string,
    markUpAmount: D.number,
  }),
  D.intersect(D.partial({ orderItemId: D.string }))
);
export type OrderMarkUp = D.TypeOf<typeof OrderMarkUp>;

export const OrderPayment = D.partial({
  orderPaymentId: D.string,
  orderId: D.string,
  paymentAmount: numberOrString,
  paymentStatus: D.string,
  paymentId: D.string,
  paymentType: D.string,
  last4: D.nullable(D.string),
  expiresMonth: D.nullable(numberOrString),
  expiresYear: D.nullable(numberOrString),
});
export type OrderPayment = D.TypeOf<typeof OrderPayment>;

export const customerTypeDecoder = D.literal(
  "EMPLOYEE",
  "CUSTOMER",
  "INVESTOR",
  "COMMERCIAL",
  "TRADEPARTNER",
  "VIP"
);

export const orderStatusDecoder = D.literal(
  "PENDING",
  "PAID",
  "COMPLETED",
  "REFUNDED",
  "ERROR",
  "NOTPAID",
  ""
);

export const orderRequired = {
  orderId: D.string,
  createReasonCode: D.string,
  customerId: D.string,
  items: D.array(OrderItem),
  orderStatus: orderStatusDecoder,
  orderTotal: D.string,
  orderType: OrderType,
  subTotal: D.string,
  taxTotal: D.string,
  totalMarkDowns: D.string,
  submissionAt: D.string,
  totalTaxable: D.string,
};
const orderPartial = {
  description: D.nullable(D.string),
  cashierId: D.nullable(D.string),
  storeId: D.nullable(D.string),
  deviceId: D.nullable(D.string),
  isEmployee: D.nullable(D.boolean),
  isNewCustomer: D.nullable(D.boolean),
  isNewMember: D.nullable(D.boolean),
  isNewVehicle: D.nullable(D.boolean),
  isRewash: D.nullable(D.boolean),
  lane: D.nullable(D.number),
  laneType: D.nullable(D.string),
  submissionTime: D.nullable(D.string),
  regionId: D.nullable(D.string),
  totalMarkUps: D.nullable(D.string),
  campaignId: D.nullable(D.string),
  canceledAt: D.nullable(D.string),
  canceledDate: D.nullable(D.string),
  completedIn: D.nullable(D.number),
  connectedAccountId: D.nullable(D.string),
  createdAt: D.nullable(D.string),
  customerType: D.nullable(customerTypeDecoder),
  deviceName: D.nullable(D.string),
  gender: D.nullable(D.string),
  hasManualDiscount: D.nullable(D.boolean),
  holds: D.nullable(D.array(OrderHold)),
  markDowns: D.nullable(D.array(OrderMarkDown)),
  markUps: D.nullable(D.array(OrderMarkUp)),
  mbrCouponIssuedId: D.nullable(D.string),
  mbrCouponIssuedName: D.nullable(D.string),
  stripeCustomerId: D.nullable(D.string),
  memberExpiresAt: D.nullable(D.string),
  membershipId: D.nullable(D.string),
  nextBillingAmount: D.nullable(D.string),
  orderNumber: D.nullable(D.string),
  orgOrderId: D.nullable(D.string),
  originalOrderId: D.nullable(D.string),
  payments: D.nullable(D.array(OrderPayment)),
  receiptNumber: D.nullable(D.string),
  refundedAt: D.nullable(D.string),
  refundedDate: D.nullable(D.string),
  regionNumber: D.nullable(D.string),
  serviceItemId: D.nullable(D.string),
  storeNumber: D.nullable(D.string),
  submissionDate: D.nullable(D.string),
  totalFlockDiscounts: D.nullable(D.string),
  totalManualDiscount: D.nullable(D.string),
  totalMbrRedemptions: D.nullable(D.string),
  updatedAt: D.nullable(D.string),
  vehicleCount: D.nullable(D.number),
  vehicleId: D.nullable(D.string),
  xrefId: D.nullable(D.number),
  createdBy: D.nullable(D.string),
  createdIn: D.nullable(D.string),
  updatedBy: D.nullable(D.string),
  updatedIn: D.nullable(D.string),
  flockProrationTotal: D.nullable(D.string),
};
export const Order = pipe(
  D.struct(orderRequired),
  D.intersect(D.partial(orderPartial))
);
export type Order = D.TypeOf<typeof Order>;

export const OrderHeader = pipe(
  D.struct({
    orderId: D.string,
    storeId: D.string,
    regionId: D.string,
    customerId: D.string,
    cashierId: D.string,
    deviceId: D.string,
    lane: D.number,
    orderType: OrderType,
    orderStatus: orderStatusDecoder,
    totalTaxable: D.string,
    totalMarkDowns: D.string,
    totalMarkUps: D.string,
    subTotal: D.string,
    orderTotal: D.string,
    submissionAt: D.string,
    submissionTime: D.string,
    description: D.string,
  }),
  D.intersect(
    D.partial({
      vehicleKey: D.nullable(D.string),
      vehicleVin: D.nullable(D.string),
      vehicleState: D.nullable(D.string),
      vehicleLicense: D.nullable(D.string),
      washType: D.nullable(D.string),
      laneType: D.nullable(D.string),
      customerType: D.nullable(customerTypeDecoder),
      isNewCustomer: D.nullable(D.boolean),
      isEmployee: D.nullable(D.boolean),
      storeNumber: D.nullable(D.string),
      storeState: D.nullable(D.string),
      storeCity: D.nullable(D.string),
      regionNumber: D.nullable(D.string),
      custFirstName: D.nullable(D.string),
      custLastName: D.nullable(D.string),
      cashierFirstName: D.nullable(D.string),
      cashierLastName: D.nullable(D.string),
    })
  )
);
export type OrderHeader = D.TypeOf<typeof OrderHeader>;

export const OrderHeaderArray = D.array(OrderHeader);
export type OrderHeaderArray = D.TypeOf<typeof OrderHeaderArray>;

export const OrderSearchResult = pipe(
  D.struct({
    orders: D.array(OrderHeader),
    recordCount: D.number,
    offset: D.number,
    pageSize: D.number,
    pageCount: D.number,
    pageNo: D.number,
  })
);

export type OrderSearchResult = D.TypeOf<typeof OrderSearchResult>;

export const Membership = D.struct({
  account: Account,
  vehicles: D.array(Vehicle),
  activeCoupons: D.array(CouponIssued),
});
export type Membership = D.TypeOf<typeof Membership>;

export const GetProfileResponse = pipe(
  D.struct({
    profile: QsysProfile,
  }),
  D.intersect(
    D.partial({
      membership: D.nullable(Membership),
    })
  ),
  D.intersect(
    D.partial({
      region: D.nullable(Region),
    })
  )
);
export type GetProfileResponse = D.TypeOf<typeof GetProfileResponse>;

export const Promo = D.struct({
  couponId: D.string,
  couponType: D.string,
  serialNo: D.string,
  name: D.string,
  markDownId: D.string,
  singleUse: D.boolean,
  totalIssued: D.number,
  maxRedemptions: D.number,
  remainingRedemptions: D.number,
  limitRedemptions: D.boolean,
  durationQty: D.number,
  isActive: D.boolean,
  effectiveAt: D.string,
  expiresAt: D.string,
});
export type Promo = D.TypeOf<typeof Promo>;

export const Discount = pipe(
  D.struct({
    discountId: D.string,
    markDownId: D.string,
    isLineOnly: D.boolean,

    minQty: D.number,
    maxQty: D.number,
    minAmount: D.string,
    maxAmount: D.string,
    isTaxable: D.boolean,
    overridePrice: D.string,
    priceInclTax: D.boolean,
    refItemForPrice: D.string,
    addToOrder: D.boolean,
    inclusiveAllQty: D.boolean,
    startQty: D.number,
    subscriptionOnly: D.boolean,
    effectiveAt: D.string,
    expiresAt: D.string,
  }),
  D.intersect(
    D.partial({
      itemId: D.nullable(D.string),
      variantItemId: D.nullable(D.string),
      discountAmt: D.nullable(D.string),
      discountPct: D.nullable(D.string),
    })
  )
);
export type Discount = D.TypeOf<typeof Discount>;

export const DiscountCondition = pipe(
  D.struct({
    discountConditionId: D.string,
    markDownId: D.string,
    isLineOnly: D.boolean,
    existingSubOnly: D.boolean,
    minQty: D.string,
    maxQty: D.string,
    minSubTotal: D.string,
    maxSubTotal: D.string,
    minTotal: D.string,
    maxTotal: D.string,
    startTimeOfDay: D.string,
    endTimeOfDay: D.string,
    employeeOnly: D.boolean,
    newCustOnly: D.boolean,
    newVehicleOnly: D.boolean,
    newSubOnly: D.boolean,
    minSubDurQty: D.string,
    effectiveAt: D.string,
    expiresAt: D.string,
  }),
  D.intersect(
    D.partial({
      itemId: D.nullable(D.string),
      variantItemId: D.nullable(D.string),
      subscriptionItemId: D.nullable(D.string),
      subVariantItemId: D.nullable(D.string),
      minQtyPerOrder: D.nullable(D.string),
      maxQtyPerOrder: D.nullable(D.string),
      daysOfWeek: D.nullable(D.string),
      regionId: D.nullable(D.string),
      storeId: D.nullable(D.string),
    })
  )
);
export type DiscountCondition = D.TypeOf<typeof DiscountCondition>;

export const MarkDown = pipe(
  D.struct({
    markDownId: D.string,
    name: D.string,
    markDownType: D.literal(
      "EMPLOYEEMBR",
      "MANUAL",
      "MEMBERSHIP",
      "TRIAL SUBSCRIPTION",
      "OVERRIDEMBR",
      "SMARTDISCOUNT",
      "UPGMBRSHIP"
    ),
    markDownGroup: D.literal(
      "EMPLOYEEMBR",
      "MANUAL",
      "MEMBERSHIP",
      "OVERRIDEMBR",
      "SMART",
      "UPGMBRSHIP"
    ),
    groupExclusivePriority: D.number,
    maxAmount: D.string,
    maxPercent: D.string,
    manualOnly: D.boolean,
    systemGen: D.boolean,
    autoRedemption: D.boolean,
    couponOnly: D.boolean,
    discounts: D.array(Discount),
    conditions: D.array(DiscountCondition),
    expiresAt: D.string,
    effectiveAt: D.string,
  }),
  D.intersect(
    D.partial({
      campaignId: D.nullable(D.string),
    })
  )
);

export const Payment = pipe(
  D.struct({
    paymentType: D.literal("CREDIT", "CASH", "PREPAID"),
    paymentAt: D.string,
    transactionType: D.literal(
      "VISA",
      "MASTERCARD",
      "AMEX",
      "DISCOVER",
      "DINER",
      "CASH",
      "PREPAID",
      "CREDIT",
      "SUBSCRIPTION"
    ),
    paymentStatus: D.literal(
      "PAID",
      "PENDING",
      "AUTHORIZED",
      "SETTLED",
      "CAPTURED",
      "TOKENIZED",
      "ERROR"
    ),
  }),
  D.intersect(
    D.partial({
      customerPaymentId: D.nullable(D.string),
      paymentAmount: D.nullable(D.string),
      paymentId: D.nullable(D.string),
      token: D.nullable(D.string),
      last4: D.nullable(D.string),
      expiresAt: D.nullable(D.string),
      paymentProvider: D.nullable(D.string),
      cardHolderName: D.nullable(D.string),
      billingAddressId: D.nullable(D.string),
      verificationCode: D.nullable(D.string),
    })
  )
);
export type Payment = D.TypeOf<typeof Payment>;

export const SalesItem = pipe(
  D.struct({
    itemId: D.string,
    sku: D.string,
    name: D.string,
    itemType: SalesItemTypes,
    nameMax12: D.string,
    defaultPrice: D.string,
    priceInclTax: D.boolean,
    isTaxable: D.boolean,
    isVariant: D.boolean,
  }),
  D.intersect(
    D.partial({
      variantSku: D.nullable(unknownDecoder),
      variantItemId: D.nullable(D.string),
      variantName: D.nullable(D.string),
      description: D.nullable(D.string),
      adjustedPrice: D.nullable(D.string),
      storePrice: D.nullable(D.string),
      regionPrice: D.nullable(D.string),
      effectiveAt: D.nullable(D.string),
      effectiveOn: D.nullable(D.string),
      expiresAt: D.nullable(D.string),
      expiresOn: D.nullable(D.string),
      serialNo: D.nullable(D.string),
    })
  )
);
export type SalesItem = D.TypeOf<typeof SalesItem>;

export const CustomerPaymentMethod = pipe(
  D.struct({
    customerPaymentId: D.string,
    brand: D.string,
    expMonth: numberOrString,
    expYear: numberOrString,
    last4: D.string,
    isDefault: D.boolean,
  }),
  D.intersect(
    D.partial({
      id: D.nullable(D.string),
    })
  )
);
export type CustomerPaymentMethod = D.TypeOf<typeof CustomerPaymentMethod>;

export const CustomerPaymentMethodArray = D.array(CustomerPaymentMethod);
export type CustomerPaymentMethodArray = D.TypeOf<
  typeof CustomerPaymentMethodArray
>;

/**
 * Price Table Types
 */

export const MenuItem = pipe(
  D.struct({
    itemId: D.string,
    myQQName: D.string,
    sku: D.string,
  }),
  D.intersect(
    D.partial({
      name: D.nullable(D.string),
      price: D.nullable(D.string),
    })
  )
);
export type MenuItem = D.TypeOf<typeof MenuItem>;

export const SubscriptionPlan = pipe(
  D.struct({
    base: MenuItem,
    flock: MenuItem,
    myQQShortDisplayName: D.string,
    position: D.number,
    washHierarchy: D.number,
    availableForPurchase: D.boolean,
    washLevelId: D.string,
  }),
  D.intersect(
    D.partial({
      features: D.array(D.string),
      flavorText: D.nullable(D.string),
      backgroundImageUrl: D.nullable(D.string),
      washLevelImageUrl: D.nullable(D.string),
      washLevelBadgeColor: D.nullable(D.string),
      featureText: D.nullable(D.string),
    })
  )
);

export type SubscriptionPlan = D.TypeOf<typeof SubscriptionPlan>;

export const MenuPair = D.struct({
  base: MenuItem,
  flock: MenuItem,
});
export type MenuPair = D.TypeOf<typeof MenuPair>;

export const RegionMenu = pipe(
  D.struct({
    plans: D.array(SubscriptionPlan),
  }),
  D.intersect(
    D.partial({
      regionId: D.nullable(D.string),
      storeId: D.nullable(D.string),
    })
  )
);

export type RegionMenu = D.TypeOf<typeof RegionMenu>;

export const SubscriptionItem = D.struct({
  itemId: D.string,
  name: D.string,
  sku: D.string,
  amount: D.string,
  quantity: D.number,
  itemType: SubscriptionItemType,
});
export type SubscriptionItem = D.TypeOf<typeof SubscriptionItem>;

export const SubscriptionStatus = D.literal(
  "current",
  "past_due",
  "cancelled",
  "uninitialized"
);
export type SubscriptionStatus = D.TypeOf<typeof SubscriptionStatus>;

export const SubscriptionDetail = pipe(
  D.struct({
    renewalDate: D.string,
    renewalAmount: D.string,
    myQQShortDisplayName: D.string,
    washLevelId: D.string,
    vehicles: D.array(Vehicle),
    subscriptionItems: D.array(SubscriptionItem),
    stripeSubscription: stripeAPISubscriptionDecoder,
  }),
  D.intersect(
    D.partial({
      status: D.nullable(SubscriptionStatus),
      regionNumber: D.nullable(D.string),
    })
  )
);
export type SubscriptionDetail = D.TypeOf<typeof SubscriptionDetail>;

export const GetSubscriptionResponse = D.nullable(SubscriptionDetail);
export type GetSubscriptionResponse = Option<SubscriptionDetail>;

/**
 * Order Calculate
 */
export const PostCalculateOrderRequest = pipe(
  D.struct({
    orderId: D.string,
    customerId: D.string,
    submissionAt: D.string,
    orderType: OrderType,
  }),
  D.intersect(
    D.partial({
      createReasonCode: D.nullable(D.string),
      payments: D.nullable(D.array(OrderPayment)),
      markDowns: D.nullable(
        D.array(
          D.partial({ ...orderMarkDownRequired, ...ordermarkDownPartial })
        )
      ),
      items: D.nullable(D.array(OrderItem)),
      waiveUpgradeFee: D.nullable(D.boolean),
    })
  )
);
export type PostCalculateOrderRequest = D.TypeOf<
  typeof PostCalculateOrderRequest
>;

export const PostCalculateOrderResponse = D.partial({
  ...orderRequired,
  ...orderPartial,
});
export type PostCalculateOrderResponse = D.TypeOf<
  typeof PostCalculateOrderResponse
>;

/**
 * Order Submit
 */
export type PostSubmitOrderRequest = unknown;
export type PostSubmitOrderResponse = unknown;

/**
 * Add new payment method
 */
export const PostPaymentMethodRequest = pipe(
  D.struct({ paymentMethodId: D.string }),
  D.intersect(D.partial({ createReason: D.literal("FAILEDINVOICE") }))
);
export type PostPaymentMethodRequest = D.TypeOf<
  typeof PostPaymentMethodRequest
>;

export const PostPaymentMethodResponse = D.array(CustomerPaymentMethod);
export type PostPaymentMethodResponse = D.TypeOf<
  typeof PostPaymentMethodResponse
>;

/**
 * Account Check
 */
export type AccountCheckRequest = PaymentMethod;
export const AccountCheckLast4PlateRequest = pipe(
  D.sum("type")({
    vin: D.struct({ type: D.literal("vin"), vin: D.string }),
    plate: D.struct({
      type: D.literal("plate"),
      state: D.string,
      license: D.string,
    }),
  }),
  D.intersect(D.struct({ last4: D.string }))
);
export type AccountCheckLast4PlateRequest = D.TypeOf<
  typeof AccountCheckLast4PlateRequest
>;

export const AccountCheckResponse = D.sum("type")({
  not_found: D.struct({ type: D.literal("not_found") }),
  found_many: pipe(
    D.struct({ type: D.literal("found_many") }),
    D.intersect(
      D.partial({
        profile: D.nullable(QsysProfile),
        membership: D.nullable(Membership),
      })
    )
  ),
  found_one_has_link: D.struct({ type: D.literal("found_one_has_link") }),
  found_one_no_link: pipe(
    D.struct({ type: D.literal("found_one_no_link") }),
    D.intersect(
      D.partial({
        profile: D.nullable(QsysProfile),
        membership: D.nullable(Membership),
        stripeSubscription: D.nullable(SubscriptionDetail),
      })
    )
  ),
});
export type AccountCheckResponse = D.TypeOf<typeof AccountCheckResponse>;

export const PayInvoiceResponse = D.struct({ success: D.boolean });
export type PayInvoiceResponse = D.TypeOf<typeof PayInvoiceResponse>;

export const PromoLocation = D.partial({
  zip: D.nullable(D.string),
  lat: D.nullable(D.number),
  lng: D.nullable(D.number),
  regionId: D.nullable(D.string),
  storeId: D.nullable(D.string),
});
export type PromoLocation = D.TypeOf<typeof PromoLocation>;
export const PlanOverride = D.partial({
  position: D.nullable(D.number),
  type: D.string,
  sku: D.nullable(D.string),
  imageUrl: D.nullable(D.string),
  serialNo: D.nullable(D.string),
  fixedBasePrice: D.nullable(D.string),
  fixedFlockPrice: D.nullable(D.string),
});
export type PlanOverride = D.TypeOf<typeof PlanOverride>;
export const PromoDetails = D.partial({
  code: D.nullable(D.string),
  message: D.nullable(D.string),
  disclaimer: D.nullable(D.string),
  customOverride: D.nullable(D.string), // HTML for plans page
  vehiclePageImageUrl: D.nullable(D.string),
  location: D.nullable(PromoLocation),
  grandOpening: D.nullable(D.boolean),
  grandOpeningHomeStoreId: D.nullable(D.string),
  // whitelist of order types to auto-apply the promo to; missing/empty means all order types:
  autoApplyOrderTypes: D.nullable(D.array(OrderType)),
  crossOutDefaultPrice: D.nullable(D.boolean),
  planSpecificOverride: D.nullable(D.array(PlanOverride)),
});
export type PromoDetails = D.TypeOf<typeof PromoDetails>;

/**
 * Swap and edit membership
 */
export const AccountQuota = D.struct({
  quotaAllowed: D.number,
  quotaConsumed: D.number,
});
export type AccountQuota = D.TypeOf<typeof AccountQuota>;

export const VehicleSwapRequest = D.struct({
  currentVehicleId: D.string,
  newVehicleId: D.string,
  reasonId: D.string,
});
export type VehicleSwapRequest = D.TypeOf<typeof VehicleSwapRequest>;

export const VehicleSwapResponse = D.struct({
  currentVehicleId: D.string,
  newVehicleId: D.string,
  quotaConsumed: AccountQuota,
});
export type VehicleSwapResponse = D.TypeOf<typeof VehicleSwapResponse>;

export const SwapReason = D.struct({
  id: D.string,
  name: D.string,
  description: D.string,
  quotaConsumed: D.number,
});
export type SwapReason = D.TypeOf<typeof SwapReason>;

export const SwapReasonArray = D.array(SwapReason);
export type SwapReasonArray = D.TypeOf<typeof SwapReasonArray>;

/**
 * CancelMembership
 */

export const CancelMembershipRequest = D.struct({
  cancelReason: D.string,
});

export type CancelMembershipRequest = D.TypeOf<typeof CancelMembershipRequest>;

export const CancelMembershipResponse = D.struct({
  subscriptionId: D.string,
});

export type CancelMembershipResponse = D.TypeOf<
  typeof CancelMembershipResponse
>;

export const CheckEligibilityForRetentionOfferResponse = D.struct({
  eligible: D.boolean,
});

export type CheckEligibilityForRetentionOfferResponse = D.TypeOf<
  typeof CheckEligibilityForRetentionOfferResponse
>;

export const AcceptRetentionOfferResponse = D.struct({
  success: D.boolean,
});

export type AcceptRetentionOfferResponse = D.TypeOf<
  typeof AcceptRetentionOfferResponse
>;
export const CancelReason = D.struct({
  message: D.string,
  code: D.string,
});
export type CancelReason = D.TypeOf<typeof CancelReason>;

export const CancelReasonArray = D.array(CancelReason);
export type CancelReasonArray = D.TypeOf<typeof CancelReasonArray>;

export const AppVersion = D.struct({
  api: D.string,
  android: D.struct({
    minimum: D.string,
  }),
  web: D.struct({
    minimum: D.string,
  }),
});
export type AppVersion = D.TypeOf<typeof AppVersion>;

export const MarketingContent = pipe(
  D.struct({
    isDismissable: D.boolean,
    showIfUnauth: D.boolean,
    link: D.nullable(D.string),
    layout: D.nullable(D.string),
  }),
  D.intersect(
    D.partial({
      promoCode: D.nullable(D.string),
      planPageCardImage: D.nullable(D.string),
      vehiclesPageCardImage: D.nullable(D.string),
      homePageCardImage: D.nullable(D.string),
    })
  )
);

export type MarketingContent = D.TypeOf<typeof MarketingContent>;

export const MarketingContentArray = D.array(MarketingContent);
export type MarketingContentArray = D.TypeOf<typeof MarketingContentArray>;

export const planSpecificBenefit = D.struct({
  benefitDescription: D.string,
  contractId: D.string,
  crossOutBasePrice: D.boolean,
  itemId: D.string,
  sku: D.string,
});

export const B2bBenefit = D.struct({
  companyName: D.string,
  contractDescription: D.string,
  planSpecificBenefit: D.array(planSpecificBenefit),
});
export type B2bBenefit = D.TypeOf<typeof B2bBenefit>;

export const LinkB2CAccountResponse = D.struct({
  personId: D.string,
  signUpCode: D.string,
  signUpExp: D.string,
});

export type LinkB2CAccountResponse = D.TypeOf<typeof LinkB2CAccountResponse>;

export const LinkB2CAccountRequest = D.struct({
  personId: D.string,
  signUpCode: D.string,
});

export type LinkB2CAccountRequest = D.TypeOf<typeof LinkB2CAccountRequest>;
